$(document).ready(async function(){
  await addCarrerGobiMacroCarousel()

  // Adding Gobi API after the carousel was loaded
  checkRerunGobi()
})

function checkRerunGobi(){
  // Grabbing the first story just as sample
  const gobiStory = document.querySelector('.gobi-stories')

  // If we don't have Gobi Stories in the page then we don't bother
  if (!gobiStory) return true

  // If the Gobi Stories already have their contents loades then it's done
  if (gobiStory.children && gobiStory.children.length) return true

  // Otherwise we wait a bit and trigger the Gobi Stories again, then check again
  setTimeout(() => {
    try{ gobi.discover() }catch(e){}
    checkRerunGobi()
  }, 500)
}

async function addCarrerGobiMacroCarousel(){
  $('.gobi-carousel .carousel-wrapper').each(function(num, elem){
    elem = $(elem);
    const wrapperParent = elem.parent()
    // wrapperParent.addClass('gobi-carousel-container')

    const isCareerLayout = document.querySelector('.career-layout')

    elem.slick({
      slide: '.gobi-stories',
      infinite: false,
      dots: false,
      autoplay: false,
      arrows: true,
      appendArrows: wrapperParent,
      prevArrow: '<button id="article-gobi-previous"></button>',
      nextArrow: '<button id="article-gobi-next"></button>',
      adaptiveHeight: false,
      slidesToShow: isCareerLayout ? 4 : 3,
      slidesToScroll: isCareerLayout ? 4 : 3,
      responsive: [
        {
          breakpoint: 1080,
          settings: {
            slidesToShow: isCareerLayout ? 4 : 2,
            slidesToScroll: isCareerLayout ? 4 : 2,
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
            arrows: true,
          }
        }
      ]
    });
  })
}
